import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  
 

<article>
      <Link class="no-underline flex hover:text-white" to={post.frontmatter.path}>
                <div class="relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded-lg skBrown">
                <img alt={post.frontmatter.title} src={post.frontmatter.thumbnail}>
                </img>
                <blockquote class="relative p-8 mb-4">
                    <h4 class="text-xl font-bold text-white">
                    {post.frontmatter.title}
                    </h4>
                    <p class="text-md font-light mt-2 text-white">
                    {post.frontmatter.metaDescription}
                    </p>
                </blockquote>
            
            </div>
        </Link>
        </article>
 
  
)


export default PostLink